<template>
  <v-list subheader>
    <v-subheader>
<!--      <span style="font-size: 12px" @click="changeFilterStatus(0)" :class="{'primary&#45;&#45;text': filters.status === 0}" class="cursor-pointer">
        <v-icon small :color="filters.status === 0 ? 'primary' : 'default'">mdi-sort-clock-descending</v-icon> Chưa đọc
      </span>&nbsp;&nbsp;|&nbsp;&nbsp;
      <span style="font-size: 12px" @click="changeFilterStatus(null)" :class="{'primary&#45;&#45;text': filters.status === null}" class="cursor-pointer">
        Tất cả <v-icon small :color="filters.status === null ? 'primary' : 'default'">mdi-sort-clock-ascending</v-icon>
      </span>
      <v-spacer></v-spacer>-->
      <SelectFilter class="mr-2" style="max-width: 150px" :default-value="filters.status" :options="notifyStatusOptions" :sorting="filters.sort_by"
                    :label="$t('labels.status')" :placeholder="$t('labels.status')" name="status" has-sort sort-name="status" @onFilter="onFilterChange"/>
      <SelectFilter style="max-width: 150px" :options="notifyTypeOptions" :sorting="filters.sort_by"
                    :label="$t('labels.classify')" :placeholder="$t('labels.classify')" name="type" has-sort sort-name="type" @onFilter="onFilterChange"/>
      <!--<span v-if="items.length > 0 && filters.status === 0" class="primary--text"
            :class="{'text--disabled': countDown > 0, 'cursor-pointer': countDown === 0}"
            @click="readAll">
        Đánh dấu đã đọc <span v-if="countDown > 0">({{ countDown }}s)</span>
      </span>-->
    </v-subheader>

    <template v-if="items.length > 0">
      <div v-for="(item, key) in items" :key="`notify_${item.id}`">
        <v-list-item>
          <v-list-item-avatar class="align-self-start">
            <img src="@/assets/user.png"/>
          </v-list-item-avatar>

          <v-list-item-content class="align-self-start">
            <v-list-item-title class="white-space-normal cursor-pointer" v-html="item.title" @click="showDialogDetail(item)" ></v-list-item-title>
            <v-list-item-subtitle class="white-space-normal font-italic d-flex align-center justify-space-between" style="font-size: 11px; margin: 2px 0;">
              {{ formatDateTime(item.created_at) }}
              <v-spacer></v-spacer>
              <v-chip x-small v-if="item.type" :color="notifyTypeColor[item.type]">{{ $t(`labels.notify_type_${item.type}`)}}</v-chip>
            </v-list-item-subtitle>
            <!--<v-list-item-subtitle class="white-space-normal" v-html="item.content"></v-list-item-subtitle>-->
          </v-list-item-content>

          <v-list-item-action class="align-self-start mt-1">
            <v-btn icon @click="showDialogDetail(item)">
              <v-icon color="primary" v-if="item.status === 0">mdi-email-outline</v-icon>
              <v-icon v-else>mdi-email-open-outline</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider v-if="key < (items.length - 1)"></v-divider>
      </div>

      <v-pagination
          class="mini-pagination"
          v-if="totalPage > 1"
          v-model="page"
          :length="totalPage"
          :total-visible="3"
      ></v-pagination>
    </template>
    <template v-else>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle class="white-space-normal text-center">Chưa có thông báo mới!</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-dialog v-model="dialogDetail" persistent max-width="640">
      <Detail v-if="dialogDetail" @closeDialog="closeDialogDetail" @refreshData="getList" @onNotifyChange="onNotifyChange" :select-item="selectItem" />
    </v-dialog>
  </v-list>
</template>

<script>
import {httpClient} from "@/libs/http";
import {debounce} from "lodash/function";
import {NOTIFY_STATUS_OPTIONS, NOTIFY_TYPE_COLOR, NOTIFY_TYPE_OPTIONS} from "@/libs/const";

export default {
  name: "NotifyList",
  components: {
    Detail: () => import('@/components/notification/Detail'),
    SelectFilter: () => import('@/components/table/SelectFilter'),
  },
  data: () => ({
    items: [],
    filters: {
      status: null,
      type: null,
    },
    page: 1,
    total: 0,
    totalPage: 0,
    countDown: 0,
    seconds: 0,
    dialogDetail: false,
    selectItem: {},
    notifyTypeColor: {...NOTIFY_TYPE_COLOR}
  }),
  computed: {
    notifyTypeOptions() {
      return [...NOTIFY_TYPE_OPTIONS].map(t => ({
        text: this.$t(`labels.notify_type_${t}`),
        value: t
      }))
    },
    notifyStatusOptions() {
      return [...NOTIFY_STATUS_OPTIONS].map(t => ({
        text: this.$t(`labels.notify_status_${t}`),
        value: t
      }))
    }
  },
  watch: {
    page() {
      this.getList()
    },
    filters: {
      handler() {
        this.page = 1
        this.getList()
      },
      deep: true
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    changeFilterStatus(status) {
      this.filters = {...this.filters, status}
    },
    onFilterChange(filter) {
      this.filters = {...this.filters, [filter.name]: filter.value}
    },
    getList: debounce(async function () {
      httpClient.post('/notification-list', {...this.filters, page: this.page}).then(({data}) => {
        this.total = data.total
        this.totalPage = data.totalPage
        this.items = [...data.rows]
      })
    }, 100),
    showDialogDetail(item) {
      this.selectItem = {...item}
      this.dialogDetail = true
    },
    closeDialogDetail() {
      this.dialogDetail = false
    },
    onNotifyChange(count) {
      this.$emit('onNotifyChange', count)
    },
    /*getNotifyAvatar(images) {
      if (images) {
        images = images.split(';')
        return `${process.env.VUE_APP_FILE_CDN_URL}/${images[0]}`
      }
      return require('@/assets/common/no-image.jpg')
    },
    getNotifyImages(images) {
      if (images) {
        images = images.split(';')
        return images.map(img => (`${process.env.VUE_APP_FILE_CDN_URL}/${img}`))
      }
      return []
    },
    showImages(images, index) {
      if (!images) {
        return false
      }
      index = index || 0
      images = this.getNotifyImages(images)
      viewerApi({
        options: {
          toolbar: true,
          initialViewIndex: index
        },
        images
      })
    },
    countdownForRead () {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countdownForRead()
        }, 1000)
      }
    },
    readAll() {
      const ids = [...this.items].map(item => (item.id))
      this.readNotify(ids)
    },*/
  }
}
</script>

<style scoped>

</style>
